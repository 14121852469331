import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import i18n from '@/lang';
import global from '@/mixins/global';
import '@/styles/index.scss';
import { checkVersionAutoRefresh } from '@/utils/checkVersion';

checkVersionAutoRefresh().then(() => {

    Vue.config.productionTip = false;

    Vue.use(ElementUI, {
        i18n: (key: string, value: string) => i18n.t(key, value)
    });

    Vue.mixin(global);

    new Vue({
        router,
        store,
        i18n,
        render: h => h(App)
    }).$mount('#app');
});
