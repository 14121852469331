import request from '../utils/request';

export const getCountry = (params: any) => {
    return request({
        url: '/platform/basic/country',
        method: 'GET',
        params
    });
};

export const applyForUse = (data: any) => {
    return request({
        url: '/platform/admin/clientUser/applyForUse',
        method: 'POST',
        data
    });
};

// 获取验证码
export const sendPhoneCode = (data: any, countryId: number) => {
    return request({
        url: '/platform/phoneCode/send',
        method: 'post',
        data,
        headers: {
            countryId
        }
    });
};

// 埋点
export const saveEventTracking = (data: any) => {
    return request({
        url: '/coaster/web-api/v1/eventTracking/save',
        method: 'post',
        data
    });
};
