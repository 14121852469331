import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

const MainLayout = () => import('../views/MainLayout.vue');
const HomePage = () => import('../views/HomePage.vue');

const EGGAlaiHome = () => import('../views/EGGAlai/EGGAlaiHome.vue');
const EGGAlaiPackage = () => import('../views/EGGAlai/EGGAlaiPackage.vue');
const EGGAlaiQandA = () => import('../views/EGGAlai/EGGAlaiQandA.vue');

const EGGMallHome = () => import('../views/EGGMall/EGGMallHome.vue');

const EGGRepairHome = () => import('../views/EGGRepair/EGGRepairHome.vue');
const EGGRepairCaseDetailPage = () => import('../views/EGGRepair/CaseDetailPage.vue');

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        component: MainLayout,
        children: [{
            path: '/',
            name: 'HomePage',
            component: HomePage
        }, {
            path: '/EGGAlai',
            name: 'EGGAlaiHome',
            component: EGGAlaiHome
        }, {
            path: '/EGGAlai/package',
            name: 'EGGAlaiPackage',
            component: EGGAlaiPackage
        }, {
            path: '/EGGAlai/QandA',
            name: 'EGGAlaiQandA',
            component: EGGAlaiQandA
        }, {
            path: '/EGGMall',
            name: 'EGGMallHome',
            component: EGGMallHome
        }, {
            path: '/EGGRepair',
            name: 'EGGRepairHome',
            component: EGGRepairHome
        }, {
            path: '/EGGRepair/case/:id',
            name: 'EGGRepairCaseDetailPage',
            component: EGGRepairCaseDetailPage
        }]
    }
];

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
});
router.beforeEach((to, from, next) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    next();
});
export default router;
