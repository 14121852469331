import { Vue, Component } from 'vue-property-decorator';
import { saveEventTracking } from '@/api/EGGRepair';

@Component({
    name: 'globalMixin'
})
export default class Global extends Vue {
    saveEventTracking(elementId: string) { // 埋点
        const data = {
            appId: 'RepairLoading',
            appVersion: '1.0.0',
            carrier: '',
            customizeValue: {
                inquiryDuration: null,
                total: null,
                selectName: '',
                inputName: ''
            },
            deviceId: 'deviceId',
            dynamicResourceType: 1,
            elementId,
            eventTime: Date.now(),
            eventType: 2,
            ip: '',
            isFirstTime: 2,
            latitude: '',
            libVersion: '1.0',
            longitude: '',
            manufacturer: '',
            model: '',
            networkType: '',
            operatingSystem: '',
            operatingSystemVersion: '',
            referPage: '',
            screenHeight: '',
            screenName: '',
            screenOrientation: null,
            screenWidth: '',
            sessionId: 'sessionId',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            userId: 0
        };
        return saveEventTracking(data);
    }
}
