






import { Component, Vue } from 'vue-property-decorator';

@Component({
    name: 'landing-page-web'
})
export default class extends Vue {
}
