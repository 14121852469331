/* eslint-disable */
import axios from 'axios'
import { MessageBox } from 'element-ui'

const reg = /app.(\S+).js/ // 从html中匹配出app.[hash].js中的hash
let localSoftwareVersion, // html中的app.[hash].js中的hash
    localResponseVersion, // 当前tab页缓存接口返回的版本号
    reminded = false

export function checkVersionAndRemind(response) { // 检查版本，需要更新时提示
    if (!reminded) { // 只提示一次
        const webVersion = response.headers['web-version']
        if (webVersion) {
            if (!localResponseVersion) { // checkVersionAutoRefresh保证每次打开新tab页都是最新的页面，第一个接口返回的web-version就是最新版本号
                localResponseVersion = webVersion
            } else if (localResponseVersion != webVersion) {
                remindRefresh()
                reminded = true
            }
        }
    }
}

export async function checkVersionAutoRefresh(url) { // 检查版本，需要更新时自动刷新
    if (await checkNeedUpdate()) {
        if (url) {
            window.location.href = window.location.origin + '/#' + url
        }
        window.location.reload()
    }
}

async function checkNeedUpdate() { // 判断是否需要更新
    const remoteVersion = await getRemoteVersion()
    if (!localSoftwareVersion) {
        getLocalVersion()
    }
    return remoteVersion !== localSoftwareVersion
}

async function getRemoteVersion() { // 获取远程版本号
    try {
        const res = await axios.get('/?_t=' + Date.now())
        const result = res.data.match(reg)
        if (result && result[1]) {
            return result[1]
        }
    } catch(e) {}
}

function getLocalVersion() { // 获取本地版本号
    Array.from(document.getElementsByTagName('script')).some(item => {
        const result = item.src.match(reg)
        if (result && result[1]) {
            localSoftwareVersion = result[1]
            return true
        }
    })
}

function remindRefresh() {
    const langObj = {
        en: {
            check_version_refresh_tips: `There's new feature released, please refresh the page to make sure you can use the system smoothly later`,
            ok_i_see: 'Yes, I see',
        },
        th: {
            check_version_refresh_tips: `เนื่องจากระบบมีการอัพเดท กรุณารีเฟรชหน้านี้เพื่อให้รองรับการใช้งานได้ดียิ่งขึ้น`,
            ok_i_see: 'ใช้ ฉันเห็น',
        },
        in: {
            check_version_refresh_tips: `Ada fitur baru yang dirilis, harap perbaharui halaman untuk memastikan Anda dapat menggunakan sistem dengan lancar nanti.`,
            ok_i_see: 'Ya, saya mengerti',
        },
        ko: {
            check_version_refresh_tips: '새로운 기능이 출시되었습니다. 나중에 시스템을 원활하게 사용할 수 있도록 페이지를 새로고침하세요.',
            ok_i_see: '네, 알겠습니다',
        },
        my: {
            check_version_refresh_tips: 'Terdapat ciri baharu yang dikeluarkan, sila muat semula halaman untuk memastikan anda boleh menggunakan sistem dengan lancar kemudian',
            ok_i_see: 'Ya, saya faham',
        },
        ph: {
            check_version_refresh_tips: 'May bagong feature na inilabas, paki-refresh ang page para matiyak na magagamit mo nang maayos ang system mamaya',
            ok_i_see: 'Oo, nakikita ko',
        },
        vi: {
            check_version_refresh_tips: 'Có tính năng mới được phát hành, vui lòng làm mới trang để đảm bảo bạn có thể sử dụng hệ thống trơn tru sau này',
            ok_i_see: 'Có, tôi đã hiểu',
        },
        zh: {
            check_version_refresh_tips: '有新功能发布，请刷新页面以确保您以后可以顺利使用系统',
            ok_i_see: '好的, 我明白了',
        },
    }
    const lang = langObj[localStorage.getItem('locale') || 'th']
    MessageBox.alert('', lang.check_version_refresh_tips, {
        confirmButtonText: lang.ok_i_see,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        showClose: false
    })
}

/* eslint-disable */
