import axios from 'axios';
import { Message } from 'element-ui';
import { AppModule } from '@/store/modules/app';
import { checkVersionAndRemind } from '@/utils/checkVersion';

const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 5000
});

/**
 * 请求拦截器
 */
service.interceptors.request.use((config) => {
    // 增加请求逻辑的内容写在这里，例如token
    if (config.headers.langCode) {
        config.headers.langCode = config.headers.langCode === 'en' ? 'en-TH' : 'th-TH';
    } else if (AppModule.locale) {
        config.headers.langCode = AppModule.locale === 'en' ? 'en-TH' : 'th-TH';
    }
    config.headers.countryId = '1001';
    config.headers.businessPlatform = 'LANDING_PAGE_WEB';
    return config;
}, (error) => {
    Promise.reject(error);
});

/**
 * 响应拦截器
 */
service.interceptors.response.use((response) => {
    checkVersionAndRemind(response);
    const res = response.data;
    if (res.code !== '200') {
        const returnCode:any = ['1202', '10000001', '20049024'];
        if (returnCode.includes(res.code)) {
            return res;
        }
        Message({
            message: res.message || 'Error',
            type: 'error',
            duration: 2 * 1000
        });
        return Promise.reject(new Error(res.message || 'Error'));
    } else {
        return res;
    }
}, (error) => {
    Message({
        message: error.message,
        type: 'error',
        duration: 2 * 1000
    });
    return Promise.reject(error);
});

export default service;
